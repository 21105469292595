// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------

import Overlay from '../../libraries/overlay.js'
import { copyTextToClipboard } from './functions.js'
import $ from 'jquery'

// ---------------------------------------------------------------------------
// Fonction gérant l'overlay de partage
// ---------------------------------------------------------------------------
export const overlayShare = (root = document) => {
  let siteName = window.config.site_name

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root,
      disabledPageScroll: true,
    }
  })

  share.init()

  $('.js-copy-share').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)

    if(window.config.lang == 'fr') {
      alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    } else if(window.config.lang == 'en') {
      alert('The URL address has been copied to your clipboard.')
    }

    return false
  })

  $('.js-share').on('click', function(e) {
    if(e.currentTarget.dataset.rubric){
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}

// ---------------------------------------------------------------------------
// Fonction gérant l'overlay menu
// ---------------------------------------------------------------------------
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: { buttons: { toggle: '.js-menu', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
      disabledPageScroll: true,
    }
  })

  menu.init()
}
