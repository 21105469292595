// |--------------------------------------------------------------------------
// | Formulaires et validation
// |--------------------------------------------------------------------------
// |
// | Validation, messages d'erreur, formatage de champs et divers à propos des
// | formulaires, c'est ici !
// |--------------------------------------------------------------------------


import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'
import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'


// Permet de supprimer le contenu d'un input
export const clearInput = (root = document) => {
  const onClick = (e) => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    root: root,
    function: onClick
  })

  OBSERVER.on('clearInput')
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = (root = document) => {
  const onInput = (e) => {
    let textHeight = e.currentTarget.scrollHeight + 2
    e.currentTarget.style.height = `${textHeight <= 32 ? 32 : textHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    root: root,
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}


// Permet de changer le label des input files
export const fileUpload = (root = document) => {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i+1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            root: root,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    root: root,
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  let themePath = window.config.theme_path

  if (!isMobile())
    tail.select('select', { animate: false })

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll('.tail-select')
  selectBoxesLength = selectBoxes.length

  for (i=0; i<selectBoxesLength; i++){ //Looper dans chaques boites de sélections Tail Select
    var tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    var nativeSelect = selectBoxes[i].previousElementSibling
    var nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-arrow')
    svgElem.appendChild(useElem)
    //selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem)

    for (j=0; j<nativeSelectItems.length; j++){ //Looper dans chaques item du Tail Select
      var imgPath = nativeSelectItems[j].dataset.image
      if((typeof imgPath !== 'undefined') && imgPath !='' ){
        var newImage = document.createElement('img')
        newImage.src = imgPath
        tailSelectItems[j].classList.add('has-image')
        tailSelectItems[j].appendChild(newImage)
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat sélectionné
  // Pas utilisé pour le moment
  /*
  OBSERVER.add({
    name: 'tailSelectChange',
    event: 'change',
    root: document,
    target: '.field--select select',
    function: tailSelectChange
  })
  OBSERVER.on('tailSelectChange')

  function tailSelectChange(e){
    var tailActive = e.target.parentElement.querySelector('.label-inner')

    if(tailActive){
      var selectedTailItem = e.target.parentElement.querySelectorAll('.tail-select .dropdown-option')[e.target.selectedIndex-1]
      if(selectedTailItem.classList.contains('has-image')){
        var newImage = document.createElement('img')
        newImage.src = selectedTailItem.querySelector('img').src
        tailActive.appendChild(newImage)
      }
    }

  }
  */
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;

      //console.log(validator)

      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if(nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {

      switch(element[0].getAttribute('type')) {
      // Exeption pour les radio buttons ou checkbox
      case 'radio':
      case 'checkbox':
        error.appendTo(element.parent().parent().parent().find('.js-form-error'))
        break
      // Types texts et autres
      default:
        error.appendTo(element.parent().find('.js-form-error'))
      }


    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}


export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

export function formatPostalCode(element) {
  $(element).mask('Z0Z 0Z0', {
    translation: {
      'Z': {
        pattern: /[A-Z, a-z]/
      }
    }
  })
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Validation du formulaire de example'
export function formExample() {
  validationEmail()
  formatPhone('.js-form-phone')

  var m = getMessages()

  var rules = {
    dropdownExample:    { required: true },
    firstname:          { required: true },
    lastname:           { required: true },
    email:              { required: true, courriel: true },
    phone:              { required: true, minlength: 12 },
    message:            { required: true },
    cv:                 { required: true },
    radioExampleInline: { required: true },
    checkbox:           { required: true },
  }
  var messages = {
    dropdownExample:    { required: m.required },
    firstname:          { required: m.required },
    lastname:           { required: m.required },
    email:              { required: m.required, courriel: m.email },
    phone:              { minlength: m.phone },
    message:            { required: m.required },
    cv:                 { required: m.required },
    radioExampleInline: { required: m.required },
    checkbox:           { required: m.required },
  }

  formValidation('#form-example', '#form-example-submit', rules, messages)
}

// Reset de validation
export function formSuccess( form, data ) {

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading')
    $('.stripe-loading-indicator').addClass('loaded')
    $(form)[0].reset()
    resetDropzone(form)
    if ( typeof grecaptcha !== 'undefined' ) grecaptcha.reset()

    //<div class="alert alert-success">x</div>

    var message = 'Le formulaire est bien envoyé !'
    var confirmationContainer = form.querySelector('.js-confirmation-message')

    if(confirmationContainer.innerHTML != ''){
      message = confirmationContainer.innerHTML
    }else{
      if(document.getElementsByTagName('html')[0].getAttribute('lang') == 'en'){
        message = 'The form has been sent !'
      }
    }

    // Créer l'élément
    var confirmationNode = document.createElement('div')
    confirmationNode.className = 'alert alert-success'
    confirmationNode.textContent = message

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode)

    // Cacher le formulaire
    form.style.display='none'

    //$.oc.flashMsg({text: message ,class: 'success'})
  }

}

// RESET LES DROPZONE DU FORMULAIRE
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  let dropZoneList = $('.fileField',$(formObj))

  // reset des dropzones du form
  dropZoneList.each(function( index, element ) {
    // pour chaque drop zone trouvé dans le form
    // on cherche son instance de Dropzone
    let dropZoneInstance = getDropzone( element.id )
    dropZoneInstance.removeAllFiles()
  })

}

// Retourne la dropZone en fonction de son nom
export function getDropzone( name ) {
  let selectedDropzone = false
  Dropzone.instances.forEach(function (dropzone) {
    if ( dropzone.element.id == name ) selectedDropzone = dropzone
  })
  return selectedDropzone
}

// Retourne la dropZone en fonction de son nom
export function masksDynamicForm() {
  formatPhone('.mask-phone')
  formatPostalCode('.mask-postalcode')
}

// Initialisation du calendrier
export function calendarInput() {

  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {
    $.fn.datepicker.dates['fr'] = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate')
    })

    $('.input-calendar input').on('focus', function() {
      $(this).parent().find('.datepickerWrapper').addClass('show')
    })

    $('.datepickerWrapper').on('changeDate', function() {
      $(this).prev().val($(this).datepicker('getFormattedDate'))
      $('.datepickerWrapper').removeClass('show')
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: '.input-calendar',
      function: preventClose
    })

    OBSERVER.on('input-calendar')

    function closeCalendar() {
      let x, i
      x = document.querySelectorAll('.datepickerWrapper')

      for (i = 0; i < x.length; i++) {

        x[i].classList.remove('show')
      }
    }
    function preventClose(e) {
      e.stopPropagation()
    }

  // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $('.input-calendar input').attr('type','date')
  }
}

export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down'
      },
    })
  }
}

// La validation du formulaire d'emplois
export function formJobs() {
  validationEmail()
  formatPhone('.js-form-phone')

  var m = getMessages()

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var rules = {
    firstname:    { required: true },
    lastname:     { required: true },
    email:        { required: true, courriel: true },
    phone:        { minlength: 12 },
    cv:           { accept: 'pdf', filesize: 5242880, required: true },
    motivation:   { accept: 'pdf', filesize: 5242880 },
  }
  var messages = {
    firstname:   { required: m.required },
    lastname:    { required: m.required },
    email:       { required: m.required, courriel: m.email, email: m.email },
    phone:       { minlength: m.phone },
    cv:          { accept: m.accept, required: m.required },
    motivation:  { accept: m.accept },
  }

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages)
}

// La validation du formulaire de contact
export function formContact() {
  validationEmail()
  formatPhone('.js-form-phone')

  var m = getMessages()

  var rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { minlength: 12 },
    message: { required: true },
  }
  var messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email : m.email },
    phone: { minlength: m.phone },
    message: { required: m.required },
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}

export function checkboxEnableButton() {
  const checkboxCookie = document.querySelector('#cookie-checkbox')

  if (typeof checkboxCookie !== 'undefined' && checkboxCookie !== null) {
    const button = document.querySelector('#form-contact-submit,#form-jobs-submit')

    const checkboxOnClickToggleButton = () => {
      if (checkboxCookie.checked) {
        button.classList.remove('disabled')
        button.removeAttribute('disabled')
      } else {
        button.classList.add('disabled')
        button.setAttribute('disabled', '')
      }
    }

    OBSERVER.add({
      name: 'checkboxCookie',
      event: 'click',
      target: checkboxCookie,
      function: checkboxOnClickToggleButton
    })

    OBSERVER.on('checkboxCookie')

    checkboxOnClickToggleButton()
  }
}
