// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------
import $ from 'jquery'

// Slider de la bannière | Accueil
export const slickHomeBanner = () => {
  $('.js-home-banner-image-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-home-banner-image-slider-dots',
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    asNavFor: '.js-home-banner-text-slider'
  })
  $('.js-home-banner-text-slider').slick({
    dots: false,
    arrows: false,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    asNavFor: '.js-home-banner-image-slider'
  })
}

// Slider de la section des activités | Accueil
export const slickActivities = () => {
  $('.js-activities-slider').slick({
    arrows: false,
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 400,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
        }
      }
    ]
  })

  $(document).find('.js-activities-slider-prev').on('click', () => {
    $(document).find('.js-activities-slider').slick('slickPrev')
  })

  $(document).find('.js-activities-slider-next').on('click', () => {
    $(document).find('.js-activities-slider').slick('slickNext')
  })
}

// Slider des expertises | Accueil
export const slickExpertises = () => {

  // Slider des images
  $('.js-expertises-image-slider').slick({
    arrows: false,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    fade: true,
    asNavFor: '.js-expertises-text-slider',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          autoplay: true,
          infinite: true,
        }
      }
    ]
  })

  // Slider du texte
  $('.js-expertises-text-slider').slick({
    arrows: false,
    speed: 0,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    fade: true,
    asNavFor: '.js-expertises-image-slider',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          autoplay: true,
          infinite: true,
        }
      }
    ]
  })

  $(document).find('.js-expertises-slider-prev').on('click', () => {
    $(document).find('.js-expertises-text-slider').slick('slickPrev')
  })

  $(document).find('.js-expertises-slider-next').on('click', () => {
    $(document).find('.js-expertises-text-slider').slick('slickNext')
  })

  animation()

  function animation() {
    let slider = $('.js-expertises-text-slider')
    let sliderSlides = document.querySelectorAll('.js-expertises-text-slider .slick-slide')
    let secondSlide = document.querySelector('.js-expertises-text-slider [data-slick-index="1"]')
    let slide
    let i
    if ( secondSlide ) {
      secondSlide.classList.add('removeAnimation') // Enlève l'animation de la deuxième slide
    }

    slider.on('afterChange', function () {
      for(i=0; i<sliderSlides.length; i++) {
        slide = sliderSlides[i]

        if(slide.classList.contains('slick-active')) {
          slide.classList.add('addAnimation')
          slide.classList.remove('removeAnimation')
        } else {
          slide.classList.remove('addAnimation')
          slide.classList.add('removeAnimation')
        }
      }
    })
  }
}

// Slider de la section des réalisations | Accueil
export const slickRealisations = () => {
  $('.js-realisations-slider').slick({
    arrows: false,
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 400,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
        }
      }
    ]
  })

  $(document).find('.js-realisations-slider-prev').on('click', () => {
    $(document).find('.js-realisations-slider').slick('slickPrev')
  })

  $(document).find('.js-realisations-slider-next').on('click', () => {
    $(document).find('.js-realisations-slider').slick('slickNext')
  })
}

// Slider d'image | À propos
export const slickAbout = () => {
  $('.js-about-slider').slick({
    arrows: false,
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
        }
      },
      {
        breakpoint: 400,
        settings: {
          centerMode: false,
          variableWidth: false,
          slidesToShow: 1,
        }
      },
    ]
  })

  $(document).find('.js-about-slider-prev').on('click', () => {
    $(document).find('.js-about-slider').slick('slickPrev')
  })

  $(document).find('.js-about-slider-next').on('click', () => {
    $(document).find('.js-about-slider').slick('slickNext')
  })
}

// ---------------------------------------------------------------------------
// Fonction permettant de détruire un carousel Slick
// ---------------------------------------------------------------------------
export function destroySlick(delay = 490) {
  setTimeout(() => {
    $('.slick-initialized').slick('unslick')
  }, delay)
}
